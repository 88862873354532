import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PasswordIcon from "@mui/icons-material/Password";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Container, Box } from "@mui/material";
import CustomizedProgressBars from "../CustomizedProgressBars";
import PageNotFound from "../404";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import BusinessIcon from "@mui/icons-material/Business";
import Footer from "../Footer";
import Banner from "../Banner";
import { firebaseConfig, languageType } from "../../../Utility/Helper";
import { ModeComment } from "@mui/icons-material";
import moment from 'moment';
const RegisterForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    sector: "",
    stage: "",
    location: "",
    businessStructure: "",
    hearAboutUs: "", // Add hearAboutUs to formData
    language:""
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    companyName: false,
    email: false,
    mobileNumber: false,
    password: false,
    confirmPassword: false,
    sector: false,
    stage: false,
    location: false,
    businessStructure: false,
    hearAboutUs: false, // Add hearAboutUs to errors
    language:false
  });
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isFirstSubmit, setIsFirstSubmit] = useState(false); // Add this line
  const [loading, setLoading] = useState(true);
  const [urlValid, setUrlValid] = useState(false);
  const [data, setData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [sectorData, setSectorData] = useState([]);
  const [stageData, setStageData] = useState([]); // Add state for stage data
  const [locationData, setLocationData] = useState([]); // Add state for location data
  const [businessStructureData, setBusinessStructureData] = useState([]); // Add state for business structure data
  const [hearAboutUsOptions, setHearAboutUsOptions] = useState([]);



  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const validationURL = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application/get-limited-contest-data/${id}`,
        {
          headers: {
            'Cache-Control': 'no-cache'
          },
        }
      );
      if (response.ok) {
        setUrlValid(true);

        const data1 = await response.json();
        // Log the response body

        setData(data1);

        // Fetch the image URL from Firebase Storage
        if (
          data1.contestBanner &&
          data1.contestBanner[0] &&
          data1.contestBanner[0].uploadedFileName
        ) {
          // Fetch the image URL from Firebase Storage
          const fileRef = ref(
            storage,
            `/contest-banner/${data1.contestBanner[0].uploadedFileName}`
          );
          try {
            const downloadURL = await getDownloadURL(fileRef);
            setImageUrl(downloadURL);
          } catch (error) {
            console.error("Error fetching image URL:", error);
            // Handle the error gracefully, e.g., by setting a default image URL
            // setImageUrl("/purpletree.png");
          }
        } else {
          // Handle the case where contest banner file name is not present in the response
          console.error("Contest banner file name not found in response data");
          // Set a default image URL or perform other necessary actions
          setImageUrl("/purpletree.png");
        }

        // Fetch the sector dropdown list
        const sectorResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/sector/drop-down-list`,
          {
            headers: {
              'Cache-Control': 'no-cache'
            },
          }
        );
        if (sectorResponse.ok) {
          const sectorData = await sectorResponse.json();
          setSectorData(sectorData); // Assuming you have a state to hold sector data
        } else {
          console.error("Failed to fetch sector data");
        }

        // Fetch the Stage dropdown list

        const stageResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/stage/drop-down-list`,
          {
            headers: {
              'Cache-Control': 'no-cache'
            },
          }
        );
        if (stageResponse.ok) {
          const stageData = await stageResponse.json();
          setStageData(stageData);
        } else {
          console.error("Failed to fetch stage data");
        }

        // Fetch Location

        const locationResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/state/drop-down-list`,
          {
            headers: {
              'Cache-Control': 'no-cache'
            },
          }
        );
        if (locationResponse.ok) {
          const locationData = await locationResponse.json();
          setLocationData(locationData);
        } else {
          console.error("Failed to fetch location data");
        }

        // Fetch Business Structure

        const businessStructureResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/business-structure/drop-down-list`,
          {
            headers: {
              'Cache-Control': 'no-cache'
            },
          }
        );
        if (businessStructureResponse.ok) {
          const businessStructureData = await businessStructureResponse.json();
          setBusinessStructureData(businessStructureData);
        } else {
          console.error("Failed to fetch business structure data");
        }

        // Hear About us api call
        const hearResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/hear-about/drop-down-list`,
          {
            headers: {
              'Cache-Control': 'no-cache'
            },
          }
        );
        if (hearResponse.ok) {
          const hearData = await hearResponse.json();
          setHearAboutUsOptions(hearData);
        } else {
          console.error("Failed to fetch stage data");
        }
      } else {
        setUrlValid(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Please Check The URL, It`s Not a Valid Url ", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    validationURL();
  }, [id]);

  useEffect(() => {
    // Validate form on first submit button click

    if (isFirstSubmit) {
      const formErrors = {};
      let hasErrors = false;

      Object.entries(formData).forEach(([key, value]) => {
        if (value.trim() === "") {
          formErrors[key] = true;
          hasErrors = true;
        }
      });

      // Check email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (formData.email.trim() !== "" && !emailRegex.test(formData.email)) {
        formErrors.email = true;
        hasErrors = true;
      }

      // Check mobile number digits
      if (
        formData.mobileNumber.trim() !== "" &&
        (formData.mobileNumber.trim().length !== 10 ||
          isNaN(formData.mobileNumber.trim()))
      ) {
        formErrors.mobileNumber = true;
        hasErrors = true;
      }

      // Check first name length
      if (formData.firstName.trim().length < 2) {
        formErrors.firstName = true;
        hasErrors = true;
      }
      // Check first name length
      if (formData.companyName.trim().length < 2) {
        formErrors.companyName = true;
        hasErrors = true;
      }
      // Check last name length
      if (formData.lastName.trim().length < 2) {
        formErrors.lastName = true;
        hasErrors = true;
      }

      setErrors(formErrors);

      // Set isFirstSubmit to false after initial form validation
      setIsFirstSubmit(false);
    }
  }, [formData, isFirstSubmit]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "",
    }));
  };

  const handleSectorChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      sector: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      sector: value === "",
    }));
  };
  const handleStageChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      stage: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      stage: value === "",
    }));
  };
  const handleLocationChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      location: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      location: value === "",
    }));
  };

  const handleBusinessStructureChange = (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      businessStructure: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      businessStructure: value === "",
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validation logic on blur
    if (name === "mobileNumber") {
      if (value.trim().length !== 10 || isNaN(value.trim())) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: true,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false,
        }));
      }
    } else if (name === "firstName" || name === "lastName") {
      if (value.trim().length < 2) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: true,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false,
        }));
      }
    } else {
      if (value.trim() === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: true,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false,
        }));
      }
    }

    // Reset password match error on password change
    if (name === "password" || name === "confirmPassword") {
      setPasswordMatchError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFirstSubmit(true);

    // Check for sector, stage, location, businessStructure, and hearAboutUs selection
    if (
      formData.sector === "" ||
      formData.stage === "" ||
      formData.location === "" ||
      formData.businessStructure === "" ||
      formData.hearAboutUs === "" ||
      formData.language === "" 
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        sector: formData.sector === "",
        stage: formData.stage === "",
        location: formData.location === "",
        businessStructure: formData.businessStructure === "",
        hearAboutUs: formData.hearAboutUs === "",
        language: formData.language === "",
      }));
      return;
    }

    const hasErrors =
      Object.values(errors).some((error) => error === true) ||
      passwordMatchError;

    if (hasErrors) {
      // If there are errors, do not proceed with submission
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      // If passwords do not match, show an error message
      toast.error("Both Passwords must match!", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/application`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Cache-Control': 'no-cache',
          },
          body: JSON.stringify({
            formId: id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            companyName: formData.companyName,
            email: formData.email,
            phoneNumber: formData.mobileNumber,
            password: formData.password,
            sector: formData.sector,
            stage: formData.stage,
            state: formData.location,
            businessStructure: formData.businessStructure,
            hearAboutUs: formData.hearAboutUs,
            language: formData.language,
            status: "draft",
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const { accessToken, publicId } = data;

        // Create user object with necessary details
        const user = {
          accessToken: accessToken,
          userId: publicId,
          contestId: id,
        };

        // Store user object in local storage
        localStorage.setItem("user", JSON.stringify(user));

        // Navigate to the home page
        navigate(`/dashboard/${id}`);
        // Perform a hard refresh
        window.location.reload(true);
        toast.success("Registered Successfully.", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
          hideProgressBar: true,
        });
      } else {
        const errorMessage = await response.json(); // Assuming error message is in JSON format
        console.error("Registration failed:", errorMessage.message);

        if (
          Array.isArray(errorMessage.message) &&
          errorMessage.message.length > 0
        ) {
          errorMessage.message.forEach((error) => {
            toast.error(error, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
              hideProgressBar: true,
            });
          });
        } else if (typeof errorMessage.message === "string") {
          console.error("Error Message:", errorMessage.message);
          toast.error(errorMessage.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          });
        } else {
          // In case the error message is not an array or string, display a generic error
          toast.error("An unexpected error occurred. Please try again later.", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
            hideProgressBar: true,
          });
        }
      }
    } catch (error) {
      console.error("Registration failed:", error.message);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
        hideProgressBar: true,
      });
    }
  };

  const handleBack = () => {
    navigate(`/login/${id}`); // Navigate back to the login page
  };
  const handleLogin = () => {
    navigate(`/login/${id}`); // Navigate back to the login page
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  if (loading) {
    return (
      <>
        <CustomizedProgressBars />
      </>
    );
  }
  return (
    <>
      {urlValid ? (
        <>
          {/* Navbar */}

          <Banner />
          <Container
            sx={{
              backgroundColor: "#2d75a1",
              width: "100%",
              // height: "100px",
              height: "auto",
              minHeight:"100px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              mt: 2, // Add spacing to the top
            }}
          >
            <Typography variant="h4" align="center" sx={{ fontWeight: "bold" }}>
              Contest Name:{" "}
              {data && data.contestName ? data.contestName : "No Data Found"}
            </Typography>
            {/* <Typography variant="body1" align="left">
              {data && data.contestDescription
                ? data.contestDescription
                : "No Data Found"}
            </Typography> */}
            <Typography variant="body1" align="left">
              Sponsored By -{" "}
              {data && data.companyName ? data.companyName : "No Data Found"}
            </Typography>
          </Container>

          <Container>
            <Grid
              container
              spacing={5}
              alignItems="center"
              justifyContent="center"
              py={1}
            >
              {/* For Demo Purpose */}
              {/* <Grid item xs={12} md={5}>
                <Box textAlign="center">
                  <img
                    src="https://bootstrapious.com/i/snippets/sn-registeration/illustration.svg"
                    alt=""
                    className="img-fluid mb-3 d-none d-md-block"
                    style={{
                      maxWidth: "400px",
                      background: "cadetblue",
                      borderRadius: "50px",
                      border: "15px solid cadetblue",
                    }}
                  />
                </Box>
              </Grid> */}

              {/* Registration Form */}
              <Grid item xs={12} md={7}>
                <Typography
                  variant="h5"
                  color="primary"
                  textAlign={"center"}
                  sx={{ mb: 2 }}
                  gutterBottom
                >
                  Create An Account
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} direction="column">
                    {/* First Name */}
                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="First Name"
                          name="firstName"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <PermIdentityIcon
                                sx={{
                                  mr: 1,
                                  color: errors.firstName ? "red" : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),
                          }}
                          value={formData.firstName}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.firstName}
                          fullWidth
                          variant="outlined"
                          helperText={
                            errors.firstName
                              ? "First Name should be at least 2 letters"
                              : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                        />
                      </Grid>{" "}
                      {/* Last Name */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Last Name"
                          name="lastName"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <PermIdentityIcon
                                sx={{
                                  mr: 1,
                                  color: errors.lastName ? "red" : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),
                          }}
                          value={formData.lastName}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.lastName}
                          fullWidth
                          variant="outlined"
                          helperText={
                            errors.lastName
                              ? "Last Name should be at least 2 letters"
                              : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                        />{" "}
                      </Grid>
                    </Grid>

                    {/* Company Name */}

                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Company Name"
                          name="companyName"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <BusinessIcon
                                sx={{
                                  mr: 1,
                                  color: errors.companyName ? "red" : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),
                          }}
                          value={formData.companyName}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.companyName}
                          fullWidth
                          variant="outlined"
                          helperText={
                            errors.companyName
                              ? "Company Name should be at least 2 letters"
                              : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                        />
                      </Grid>{" "}
                    </Grid>
                    {/* Sector and Stage */}

                    <Grid item container spacing={2}>
                      {/* Sector */}
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="sector-select-label">
                            Sector
                          </InputLabel>
                          <Select
                            labelId="sector-select-label"
                            id="sector-select"
                            value={formData.sector}
                            onChange={handleSectorChange}
                            label="Sector"
                            error={!!errors.sector}
                          >
                            {sectorData.map((sector) => (
                              <MenuItem key={sector._id} value={sector.name}>
                                {sector.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.sector && (
                            <FormHelperText error>
                              Please Select Sector
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>{" "}
                      {/* Stage */}
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="stage-select-label">Stage</InputLabel>
                          <Select
                            labelId="stage-select-label"
                            id="stage-select"
                            value={formData.stage}
                            onChange={handleStageChange}
                            label="Stage"
                            error={!!errors.stage}
                          >
                            {stageData.map((stage) => (
                              <MenuItem key={stage._id} value={stage.name}>
                                {stage.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.stage && (
                            <FormHelperText error>
                              Please Select Stage
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>{" "}
                    </Grid>

                    {/* Location States ////   Business */}

                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="location-select-label">
                            Location/State
                          </InputLabel>
                          <Select
                            labelId="location-select-label"
                            id="location-select"
                            value={formData.location}
                            onChange={handleLocationChange}
                            label="Location/State"
                            error={!!errors.location}
                          >
                            {locationData.map((location) => (
                              <MenuItem
                                key={location._id}
                                value={location.name}
                              >
                                {location.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.location && (
                            <FormHelperText error>
                              Please Select Location/State
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {/* Buysiness Dropdown */}
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="business-structure-select-label">
                            Business Structure
                          </InputLabel>
                          <Select
                            labelId="business-structure-select-label"
                            id="business-structure-select"
                            value={formData.businessStructure}
                            onChange={handleBusinessStructureChange}
                            label="Business Structure"
                            error={!!errors.businessStructure}
                          >
                            {businessStructureData.map((structure) => (
                              <MenuItem
                                key={structure._id}
                                value={structure.name}
                              >
                                {structure.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.businessStructure && (
                            <FormHelperText error>
                              Please Select Business Structure
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Email Address */}
                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Email"
                          name="email"
                          size="small"
                          value={formData.email}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.email}
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <EmailIcon
                                sx={{
                                  mr: 1,
                                  color: errors.email ? "red" : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),
                          }}
                          type="email"
                          helperText={errors.email ? "Invalid Email" : ""}
                          autoComplete="off" // Add this prop to disable auto suggestion
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Mobile Number"
                          name="mobileNumber"
                          size="small"
                          value={formData.mobileNumber}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.mobileNumber}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <LocalPhoneIcon
                                sx={{
                                  mr: 1,
                                  color: errors.mobileNumber
                                    ? "red"
                                    : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),
                          }}
                          variant="outlined"
                          helperText={
                            errors.mobileNumber
                              ? "Mobile number needs 10 digits"
                              : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      {/* Password */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.password}
                          size="small"
                          fullWidth
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          helperText={
                            errors.password ? "Password cannot be empty" : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                          InputProps={{
                            startAdornment: (
                              <LockIcon
                                sx={{
                                  mr: 1,
                                  color: errors.password ? "red" : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),

                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleTogglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon sx={{ maxWidth: "18px" }} />
                                  ) : (
                                    <VisibilityOffIcon
                                      sx={{ maxWidth: "18px" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      {/* Password Confirmation */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Confirm Password"
                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleChange}
                          error={errors.confirmPassword}
                          fullWidth
                          size="small"
                          variant="outlined"
                          type={showConfirmPassword ? "text" : "password"}
                          helperText={
                            errors.confirmPassword
                              ? " Confirm Password cannot be empty"
                              : ""
                          }
                          autoComplete="off" // Add this prop to disable auto suggestion
                          InputProps={{
                            startAdornment: (
                              <LockIcon
                                sx={{
                                  mr: 1,
                                  color: errors.confirmPassword
                                    ? "red"
                                    : "inherit",
                                  maxWidth: "18px",
                                }}
                              />
                            ),

                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={
                                    handleToggleConfirmPasswordVisibility
                                  }
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityIcon sx={{ maxWidth: "18px" }} />
                                  ) : (
                                    <VisibilityOffIcon
                                      sx={{ maxWidth: "18px" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>

                    {/* Hear About Us */}
                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="hear-about-us-select-label">
                            From Where Do You Hear About Us?
                          </InputLabel>
                          <Select
                            labelId="hear-about-us-select-label"
                            id="hear-about-us-select"
                            name="hearAboutUs"
                            value={formData.hearAboutUs}
                            onChange={handleDropdownChange}
                            label="From Where Do You Hear About Us?"
                            error={!!errors.hearAboutUs}

                          >
                            {hearAboutUsOptions.map((option) => (
                              <MenuItem key={option._id} value={option.name} style={{
                                whiteSpace: "break-spaces",
                                wordBreak: "break-word"
                            }}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.hearAboutUs && (
                            <FormHelperText error>
                              Please Select Where You Heard About Us
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                    {/* Language */}

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="language-label">
                           Language to be used in Application
                          </InputLabel>
                          <Select
                            labelId="language-label"
                            id="language-select"
                            name="language"
                            value={formData.language}
                            onChange={handleDropdownChange}
                            label="Language to be used in Application"
                            error={!!errors.language}
                          
                          >
                          
                            {languageType.map((option) => (
                              <MenuItem key={`lang-${option.value}`} value={option.value} >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.language && (
                            <FormHelperText error>
                              Please Select Language to be used in Application
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>


                    {/* Submit Button */}
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      {/* <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, marginRight: 2 }}
                    onClick={handleBack}
                  >
                    Back
                  </Button>{" "} */}
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        sx={{ mt: 2 }}
                        disabled={data?.contestEndDate?moment().isAfter( data.contestEndDate):false}
                       
                      >
                        Create Your Account
                      </Button>
                    </Grid>

      

                    {/* Divider Text */}
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <Box
                          flexGrow={1}
                          borderBottom={2}
                          borderColor="text.disabled"
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          px={2}
                        >
                          OR
                        </Typography>
                        <Box
                          flexGrow={1}
                          borderBottom={2}
                          borderColor="text.disabled"
                        />
                      </Box>
                    </Grid>

                    {/* Already Registered */}
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="center"
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        sx={{ marginBottom: 14 }} // Adding margin bottom using sx prop
                      >
                        Already Registered?
                        <Link onClick={handleLogin} color="primary">
                          Login
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <>
          <PageNotFound />
        </>
      )}
      <p />
      {/* <Footer /> */}
    </>
  );
};

export default RegisterForm;
